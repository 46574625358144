<template>
  <section class="pa-3">
    <h2 class="mb-10">地図プレビュー</h2>

    <div class="col-3">
      <v-select
        v-model="selectedCategory"
        :items="categoriesSelectItems"
        item-text="name"
        item-value="id"
        label="カテゴリを選択"
      />
    </div>

    <div class="mb-8">
      <div id="map" style="height: 800px">
        <GmapMap
          ref="mapRef"
          :center="
            mapCenterByCondition || {
              lat: setting.latitude,
              lng: setting.longitude,
            }
          "
          :zoom="setting.zoomLevel"
          :options="{ draggableCursor: 'default' }"
          style="width: 100%; height: 100%"
          @click="handleClickMap"
          @dragend="loadMapCenter"
        >
          <GmapMarker
            v-for="(coordinate, i) in mergedByCoordinatesPhotos"
            :key="`marker${i}`"
            :position="{
              lat: Number(coordinate.latitude),
              lng: Number(coordinate.longitude),
            }"
            :icon="pin"
            @click="handleInfoOpen(coordinate.photos)"
          >
            <GmapInfoWindow
              v-if="checkInfoOpen(coordinate)"
              :key="`infoWindow${i}`"
              @closeclick="handleInfoClose"
            >
              <div class="info_window">
                <router-link
                  v-for="photo in coordinate.photos"
                  :key="photo.id"
                  :to="`/photos/${photo.id}`"
                >
                  <div class="img_wrap">
                    <div class="l_img">
                      <img
                        :src="photo.image"
                        :alt="photo.title"
                        style="max-width: 100%"
                      />
                    </div>
                    <div class="r_info">
                      <div class="meta_ttl">
                        {{ photo.title }}
                        <span v-if="photo.status === 'unpublished'">
                          ［下書き］
                        </span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </GmapMap>
      </div>
    </div>
  </section>
</template>

<script>
import { getData } from "../../axios";
import _ from "lodash";

export default {
  name: "Index",

  data() {
    return {
      params: {
        words: "",
        id: "",
        tags: [],
        categories: [],
        distance: 200,
        limit: 500,
      },
      photos: [],
      categories: [],
      isOpen: {},
      setting: {
        latitude: null,
        longitude: null,
        zoomLevel: 13,
      },
      mapCenter: {
        latitude: 35.681236,
        longitude: 139.767124,
      },
      selectedCategory: null,
      categoriesSelectItems: [],
      selectAllItem: {
        name: "すべて",
        id: null,
      },
    };
  },

  computed: {
    client() {
      return this.$store.getters["auth/client"].setting;
    },
    mergedByCoordinatesPhotos() {
      return this.mergeByCoordinates();
    },
    mapCenterByCondition() {
      return this.selectedCategory != null
        ? this.getLatLngCenter(
            this.mergedByCoordinatesPhotos.map((v) => [v.latitude, v.longitude])
          )
        : {
            lat: this.mapCenter.latitude,
            lng: this.mapCenter.longitude,
          };
    },
    pin() {
      return this.getPin();
    },
  },

  watch: {
    selectedCategory(value) {
      this.params.categories = value ? [value] : "";
      this.$nextTick().then(() => {
        this.getPhotos();
      });
    },
  },

  created() {
    if (this.client) {
      const setting = this.$store.getters["auth/client"].setting;
      this.setting.latitude = this.round(Number(setting.latitude)) || null;
      this.setting.longitude = this.round(Number(setting.longitude)) || null;
      this.setting.zoomLevel = Number(setting.zoomLevel) || 13;
    } else {
      getData("clients").then((res) => {
        this.setting.latitude =
          this.round(Number(res.data.setting.latitude)) || null;
        this.setting.longitude =
          this.round(Number(res.data.setting.longitude)) || null;
        this.setting.zoomLevel = Number(res.data.setting.zoomLevel) || 13;
      });
    }
  },

  mounted() {
    this.getPhotos();
    this.getCategories();
  },

  methods: {
    getPhotos() {
      this.loading = true;
      const params = Object.assign({}, this.params);
      if (this.client) {
        params["lat"] = this.setting.latitude;
        params["lng"] = this.setting.longitude;
        getData("photos", params)
          .then((res) => {
            this.photos = res.data;
            this.loading = false;
            this.isOpen = Object.assign(
              {},
              ...this.photos.map((photo) => ({ [photo.id]: false }))
            );
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getCategories() {
      this.loading = true;
      getData("categories")
        .then((res) => {
          this.categories = res.data;
          this.loading = false;
          this.categoriesSelectItems = [this.selectAllItem, ...res.data];
        })
        .catch(() => (this.loading = false));
    },
    // 共通整備項目で、経度、緯度は小数点以下6桁となっているため、統一する
    round(num) {
      return Math.floor(num * 1000000) / 1000000;
    },
    mergeByCoordinates() {
      return this.photos.reduce((result, photo) => {
        const target = result.find(
          (v) =>
            v.latitude === photo.latitude && v.longitude === photo.longitude
        );
        if (target) {
          target.photos.push(photo);
        } else {
          result.push({
            latitude: photo.latitude,
            longitude: photo.longitude,
            photos: [photo],
          });
        }
        return result;
      }, []);
    },
    getPinSettings() {
      const category = this.categories.find((item) => {
        return item.id === this.selectedCategory;
      });
      return category
        ? {
            color: category.settings.pin_color || null,
            image: category.settings.pin_image || null,
          }
        : null;
    },
    getPin() {
      const pinSettings = this.getPinSettings();
      let pin = "/pin.png";
      if (this.selectedCategory) {
        if (pinSettings && pinSettings.image) {
          pin = pinSettings.image;
        } else if (pinSettings && pinSettings.color) {
          pin = {
            anchor: { x: 10, y: 33 },
            fillColor:
              pinSettings && pinSettings.color ? pinSettings.color : null,
            fillOpacity: 1,
            path: "M20.882 11.12c0-5.391-4.369-9.764-9.764-9.764v0c-5.391 0-9.764 4.369-9.764 9.764 0 4.676 3.289 8.582 7.68 9.538l1.516 9.409c0 0.316 0.253 0.569 0.569 0.569s0.569-0.253 0.569-0.569l1.516-9.409c4.391-0.956 7.68-4.862 7.68-9.538zM11.117 17.222c-3.369 0-6.102-2.733-6.102-6.102s2.733-6.102 6.102-6.102c3.369 0 6.102 2.733 6.102 6.102s-2.729 6.102-6.102 6.102zM16.108 11.116c0 2.756-2.235 4.991-4.991 4.991s-4.991-2.235-4.991-4.991c0-2.756 2.235-4.991 4.991-4.991s4.991 2.235 4.991 4.991z",
            scale: 2,
            strokeColor: "white",
            strokeOpacity: 1,
            strokeWeight: 2,
          };
        }
      }
      return pin;
    },
    handleClickMap(e) {
      this.mapCenter = { latitude: e.latLng.lat(), longitude: e.latLng.lng() };
    },
    handleMapCenter(lat, lng) {
      const d = _.debounce(() => {
        this.mapCenter = { latitude: lat, longitude: lng };
      }, 500);
      d(lat, lng);
    },
    loadMapCenter() {
      if (this.$refs.mapRef) {
        this.handleMapCenter(
          this.$refs.mapRef.center.lat,
          this.$refs.mapRef.center.lng
        );
      }
    },
    rad2degr(rad) {
      return (rad * 180) / Math.PI;
    },
    degr2rad(degr) {
      return (degr * Math.PI) / 180;
    },
    getLatLngCenter(latLngInDegr) {
      const LATIDX = 0;
      const LNGIDX = 1;
      let sumX = 0;
      let sumY = 0;
      let sumZ = 0;

      for (let i = 0; i < latLngInDegr.length; i++) {
        const lat = this.degr2rad(latLngInDegr[i][LATIDX]);
        const lng = this.degr2rad(latLngInDegr[i][LNGIDX]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
      }

      const avgX = sumX / latLngInDegr.length;
      const avgY = sumY / latLngInDegr.length;
      const avgZ = sumZ / latLngInDegr.length;

      // convert average x, y, z coordinate to latitude and longtitude
      const lng = Math.atan2(avgY, avgX);
      const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
      const lat = Math.atan2(avgZ, hyp);

      if (this.rad2degr(lat)) {
        return {
          lat: this.rad2degr(lat),
          lng: this.rad2degr(lng),
        };
      } else {
        return null;
      }
    },
    checkInfoOpen(coordinate) {
      return coordinate.photos.some((photo) => this.isOpen[photo.id]);
    },
    handleInfoOpen(targets) {
      // targetsはクリックされたマーカーと紐づいている座標ごとの写真の配列
      this.isOpen = Object.assign(
        {},
        ...Object.keys(this.isOpen).map((key) => ({
          [Number(key)]: targets
            .map((target) => target.id)
            .includes(Number(key)), // 写真をIdの配列にして検索
        }))
      );
      this.mapCenter = {
        latitude: targets[0].latitude,
        longitude: targets[0].longitude,
      };
    },
    handleInfoClose() {
      this.isOpen = Object.assign(
        {},
        ...Object.keys(this.isOpen).map((key) => ({ [Number(key)]: false }))
      );
    },
  },
};
</script>
