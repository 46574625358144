var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pa-3"},[_c('h2',{staticClass:"mb-10"},[_vm._v("地図プレビュー")]),_c('div',{staticClass:"col-3"},[_c('v-select',{attrs:{"items":_vm.categoriesSelectItems,"item-text":"name","item-value":"id","label":"カテゴリを選択"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('div',{staticClass:"mb-8"},[_c('div',{staticStyle:{"height":"800px"},attrs:{"id":"map"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.mapCenterByCondition || {
            lat: _vm.setting.latitude,
            lng: _vm.setting.longitude,
          },"zoom":_vm.setting.zoomLevel,"options":{ draggableCursor: 'default' }},on:{"click":_vm.handleClickMap,"dragend":_vm.loadMapCenter}},_vm._l((_vm.mergedByCoordinatesPhotos),function(coordinate,i){return _c('GmapMarker',{key:`marker${i}`,attrs:{"position":{
            lat: Number(coordinate.latitude),
            lng: Number(coordinate.longitude),
          },"icon":_vm.pin},on:{"click":function($event){return _vm.handleInfoOpen(coordinate.photos)}}},[(_vm.checkInfoOpen(coordinate))?_c('GmapInfoWindow',{key:`infoWindow${i}`,on:{"closeclick":_vm.handleInfoClose}},[_c('div',{staticClass:"info_window"},_vm._l((coordinate.photos),function(photo){return _c('router-link',{key:photo.id,attrs:{"to":`/photos/${photo.id}`}},[_c('div',{staticClass:"img_wrap"},[_c('div',{staticClass:"l_img"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":photo.image,"alt":photo.title}})]),_c('div',{staticClass:"r_info"},[_c('div',{staticClass:"meta_ttl"},[_vm._v(" "+_vm._s(photo.title)+" "),(photo.status === 'unpublished')?_c('span',[_vm._v(" ［下書き］ ")]):_vm._e()])])])])}),1)]):_vm._e()],1)}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }